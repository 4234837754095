
export const validateXlsImport = (rows) => {
    const counter = {}
    const errors = []
    rows.map((row, line) => {
        if (Object.keys(row).includes('U_CENE_COD_LAB')) return
        line++
        try {
            populateCounterByKey(counter, line, `[${row[3].trim()}, ${row[4].trim()}, ${row[5].trim()}, ${row[7].trim()}, ${row[8].trim()}, ${row[16].trim()}]`)
        } catch (error) {
            console.log(`validateXlsImport Brasindice error linha: ${line} `, row, error);
        }
    });

    Object.keys(counter).map((key) => {
        counter[key].length > 1 &&
            errors.push(`O item ${key} foi duplicado nas linhas [${counter[key].join(', ')}]`)
    })

    return errors.filter(error => error)
}

const populateCounterByKey = (counter, line, key) => {
    if (!counter[key]) {
        counter[key] = [line]
        return 
    }
    counter[key].push(line)
}