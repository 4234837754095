import * as CONSTANTS from '@tables/utils/constants'
import * as PROCEDURE from '@tables/utils/validations/procedure'
import * as OTHER_EXPENSE from '@tables/utils/validations/otherExpense'
// import * as PRODUCT from '@tables/utils/validations/product'
import * as BRASINDICE from '@tables/utils/validations/brasindice'
import * as SIMPRO from '@tables/utils/validations/simpro'

export const validateXlsImport = (type, rows) => {
    switch (type) {
        case CONSTANTS.TYPE_PROCEDURE:
            return PROCEDURE.validateXlsImport(rows)
        case CONSTANTS.TYPE_OTHER_EXPENSES:
            return OTHER_EXPENSE.validateXlsImport(rows)
        case CONSTANTS.TYPE_MAT_MED_SIMPRO:
            return SIMPRO.validateXlsImport(rows)
        case CONSTANTS.TYPE_MAT_MED_BRASINDICE  :
            return BRASINDICE.validateXlsImport(rows)
        case CONSTANTS.TYPE_PRODUCT:
        default:
            return new Error('Validação do tipo de item não implementada')
    }
};