import * as ITEM from '@items/utils/constants'

export const validateXlsImport = (rows) => {
    const counter = {}
    const errors = []
    rows.map((row, line) => {
        if (row[0] === 'Tipo') return
        line++
        try {
            populateCounter(counter, row, line, 1, 2)
            errors.push(validationType(row, line))
            errors.push(validationNameIsNull(row, line, 2))
            errors.push(validationTableNumber(row, line, 3))
            errors.push(validationMedicalFee(row, line))
            errors.push(validationOperationCost(row, line))
            errors.push(validationM2Filme(row, line))
            errors.push(validationAnestheticPort(row, line))
            errors.push(validationValue(row, line))
        } catch (error) {
            console.log(`validateXlsImport Procedures error linha: ${line} `, row, error);
        }
    });

    Object.keys(counter).map((key) => {
        counter[key].length > 1 &&
            errors.push(`Item ${key} duplicado nas linhas [${counter[key].join(', ')}]`)
    })

    return errors.filter(error => error)
}

const populateCounter = (counter, row, line, codePosition, namePosition) => {
    if (!row[codePosition] || !row[namePosition]) return
    const key = `[${row[codePosition]}, ${row[namePosition].toLowerCase().trim()}]`
    if (!counter[key]) {
        counter[key] = [line]
        return 
    }
    counter[key].push(line)
}

const validationType = (row, line) => !row[0] || !ITEM.PROCEDURE_ITEM_TYPE_OPTIONS.map(option => option.label.toLowerCase()).includes(row[0].toLowerCase().trim()) ? `O campo tipo na linha ${line} deve conter um dos valores [${ITEM.PROCEDURE_ITEM_TYPE_OPTIONS.map(option => option.label.toLowerCase()).join(', ')}], valor inserido: ${row[0] || 'vazio'}` : null
const validationNameIsNull = (row, line, position) => !row[position] ? `O campo nome na linha ${line} é obrigatório` : null
const validationTableNumber = (row, line, position) => row[position] && !ITEM.TISS_TABLE_OPTIONS.map(option => option.value).includes(row[position].toString()) ? `O campo tabela na linha ${line} deve conter um dos valores [${ITEM.TISS_TABLE_OPTIONS.map(option => option.value).join(', ')}], valor inserido: ${row[position]}` : null
const validationMedicalFee = (row, line) => row[4] && isNaN(parseFloat(row[4].toString().replace(',', '.'))) ? `O campo Honorário médico na linha ${line} deve ser um número` : null
const validationOperationCost = (row, line) => row[5] && isNaN(parseFloat(row[5].toString().replace(',', '.'))) ? `O campo Custo operacional na linha ${line} deve ser um número` : null
const validationM2Filme = (row, line) => row[6] && isNaN(parseFloat(row[6].toString().replace(',', '.'))) ? `O campo M2 filme na linha ${line} deve ser um número` : null
const validationAnestheticPort = (row, line) => row[7] && isNaN(parseFloat(row[7].toString().replace(',', '.'))) ? `O campo Porte anestésico na linha ${line} deve ser um número` : null
const validationValue = (row, line) => row[9] && isNaN(parseFloat(row[9].toString().replace(',', '.'))) ? `O campo Valor único na linha ${line} deve ser um número` : null