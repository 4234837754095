<template>
    <b-modal
      id="import-items-modal"
      size="lg"
      hide-header
      hide-footer
      centered
      no-stacking
      @hidden="onHidden"
    >
        <div class="header d-flex flex-row justify-content-between">
            <p class="title">Importar itens</p>
            <Close class="close" @click="close" />
        </div>
        <div class="content">
            <div class="template-wrapper">
                <multiselect
                    id="type"
                    label="label"
                    track-by="value"
                    v-model="selectedType"
                    :options="typeOptions"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    :disabled="disabled"
                    placeholder="Selecionar um tipo"
                    class="with-border"
                >
                    <template slot="caret">
                        <div class="chevron">
                            <ChevronDown />
                        </div>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
                <div v-if="selectedType" v-b-tooltip.hover :title="!selectedType ? 'Selecione um modelo para baixar' : ''">
                    <b-button 
                        :disabled="fechingCsvTemplate || !selectedType"
                        variant="outline-primary"
                        @click="getCsvTemplate"
                    >
                        Baixar modelo
                    </b-button>
                </div>
            </div>

            <div 
                class="drop-area" 
                :class="{ disabled: !selectedType }"
                @drop="dropFile" 
                @click="$refs.FileInput.click()"
                @dragover.prevent 
                @drop.prevent
            >
                <div v-if="!selectedType">Selecione o modelo que deseja importar</div>
                <div v-else>
                    <div v-if="validating">
                        <b-spinner
                            small
                            variant="light"
                            type="grow"
                        ></b-spinner>
                        Validando dados, isso pode demorar um pouco...
                    </div>
                    <div v-else-if="!file">Clique aqui ou solte o arquivo nessa área para selecionar...</div>
                    <div v-else-if="file" class="file-wrapper">
                        <FileIcon class="file-icon" />
                        <span  class="file-name">{{ file.name }}</span>
                        <div 
                            v-if="isMatMed()"    
                            @click="$event => $event.stopPropagation()"
                        >
                            <b-form-group>
                                <label for="date">Início da vigência</label>
                                <date-picker 
                                    v-model="date" 
                                    format="DD/MM/YYYY" 
                                    placeholder="DD/MM/AAAA"
                                    :lang="langDatePicker"
                                    :clearable="false"
                                    class="full"
                                ></date-picker>
                            </b-form-group>
                        </div>
                        <div class="toggle-wrapper" @click="$event => $event.stopPropagation()">
                            <toggle-button
                                :width="35"
                                v-model="canUpdate"
                                :sync="true"
                                :height="22"
                                :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                            />
                            <span>
                                Atualizar itens existentes
                            </span>
                        </div>
                        <b-button
                            variant="primary"
                            :disabled="uploading || validating || errors.length > 0"
                            @click="!errors.length ? uploadFile($event) : null"
                        >
                            <b-spinner
                                v-if="uploading"
                                small
                                variant="light"
                                type="grow"
                            ></b-spinner>
                            {{ !uploading ? 'Enviar' : 'Enviando...' }}
                        </b-button>
                    </div>
                    <input
                        class="d-none"
                        ref="FileInput"
                        type="file"
                        accept=".xlsx, .xls, .csv"
                        @click="$refs.FileInput.value = null"
                        @change="dropFile"
                    />
                </div>
            </div>

            <div class="error-wrapper">
                <div v-for="(error, index) in errors" :key="index">
                    - {{ error }}
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import api from '@items/api'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { saveAs } from 'file-saver';
import readXlsxFile from 'read-excel-file'
import { validateXlsImport } from '@tables/utils/validations'
import * as CONSTANTS from '@tables/utils/constants'

export default {
    components: {
        Close: () => import('@/assets/icons/close.svg'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        FileIcon: () => import('@items/assets/icons/file.svg')
    },
    props: {
        alternativeReference: String,
        table: {
            type: Object,
            required: true
        }
    },
    mounted() {
        if (this.table.type) {
            this.selectedType = this.typeOptions.find(({ value }) => value === this.table.type)
            this.disabled = true
        }
    },
    data() {
        return {
            CONSTANTS,
            uploading: false,
            disabled: false,
            clinic: getCurrentClinic(),
            file: null,
            validating: false,
            fechingCsvTemplate: false,
            selectedType: null,
            typeOptions: CONSTANTS.TYPE_OPTIONS,
            errors: [],
            date: new Date(),
            canUpdate: false,
            langDatePicker: {
                formatLocale: {
                    weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
                    }
                },
            }
    },
    methods: {
        close() {
            this.$bvModal.hide('import-items-modal')
            this.$emit('closeModal')
        },
        onHidden() {},
        async getCsvTemplate(subType) {
            this.fechingCsvTemplate = true
            try {
                const { data } = await api.getCsvTemplate(this.selectedType.value, subType)
                saveAs(
                    new Blob([data], { 
                        type: 'application/vnd.ms-excel' 
                    }), 
                    `${this.selectedType?.label}-template.xlsx`
                );
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.fechingCsvTemplate = false
            }
        },
        async dropFile(e) {
            const files = e.target.files || e.dataTransfer.files
            this.file = files[0]
            this.validating = true
            this.errors = []
            try {
                const rows = await readXlsxFile(files[0])
                this.errors = validateXlsImport(this.selectedType.value, rows)
                if (this.errors.length){
                    this.file = null
                }
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.validating = false
            }
        },
        async uploadFile($event) {
            $event.stopPropagation()
            const formData = new FormData()
            formData.append('file', this.file)
            formData.append('tableId', this.table.id)
            formData.append('type', this.selectedType.value)
            formData.append('clinicId', this.clinic.id)
            formData.append('canUpdate', this.canUpdate)
            this.isMatMed() && formData.append('startIn', this.moment(this.date).format('YYYY-MM-DD'))
            this.uploading = true
            try {
                await api.importItemsXlsx(this.clinic.id, formData)
                this.$toast.success('Arquivo enviado com sucesso')
                this.file = null
                this.close()
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.uploading = false
            }
        },
        isMatMed() {
            return [
                CONSTANTS.TYPE_MAT_MED_BRASINDICE, 
                CONSTANTS.TYPE_MAT_MED_SIMPRO
            ].includes(this.selectedType.value)
        }
    }
}
</script>
<style lang="scss" scoped>
    .content {
        padding: 1rem;
        .template-wrapper {
            width: 100%;
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            gap: 10px;
            margin-bottom: 1rem;
        }
        .drop-area {
            width: 100%;
            height: 300px;
            border: 1px dashed var(--neutral-200);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &.disabled {
                opacity: 0.5;
                pointer-events: none;
                color: var(--type-placeholder);
            }
            .file-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
            }
            .file-icon {
                width: 60px;
                height: 60px;
            }
            .toggle-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
            }
        }
        .error-wrapper {
            margin-top: 1rem;
            font-size: 0.8rem;
            color: var(--orange);
            max-height: 400px;
            overflow-y: auto;
        }
    }
</style>
<style lang="scss">
#import-items-modal {
    .modal-body {
        padding: 0 !important;
        .header {
            padding: 1rem;
            border-bottom: 1px solid var(--neutral-200);
            .title {
                font-weight: 600;
                font-size: 18px;
                color: var(--type-active);
                margin: 0;
            }
            .close {
                width: 24px;
                height: 24px;
                fill: black;
                cursor: pointer;
            }
        }
    }
}
</style>