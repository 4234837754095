import * as ITEM from '@items/utils/constants'

export const validateXlsImport = (rows) => {
    const counter = {}
    const errors = []
    rows.map((row, line) => {
        if (row[0] === 'Código') return
        line++
        try {
            populateCounter(counter, row, line, 0, 1)
            errors.push(validationCodeIsNull(row, line, 0))
            errors.push(validationNameIsNull(row, line, 1))
            errors.push(validationTableIsNull(row, line, 2))
            errors.push(validationTableNumber(row, line, 2))
            errors.push(validationCDIsNull(row, line, 3))
            errors.push(validationCDType(row, line, 3))
            errors.push(validationUnitaryValueIsNull(row, line, 5))
            errors.push(validationValue(row, line, 5))
        } catch (error) {
            console.log(`validateXlsImport Other expenses error linha: ${line} `, row, error);
        }
    });

    Object.keys(counter).map((key) => {
        counter[key].length > 1 &&
            errors.push(`O item ${key} foi duplicado nas linhas [${counter[key].join(', ')}]`)
    })

    return errors.filter(error => error)
}

const populateCounter = (counter, row, line, codePosition, namePosition) => {
    if (!row[codePosition] || !row[namePosition]) return
    const key = `[${row[codePosition]}, ${row[namePosition].toLowerCase().trim()}]`
    if (!counter[key]) {
        counter[key] = [line]
        return 
    }
    counter[key].push(line)
}


const validationCodeIsNull = (row, line, position) => !row[position] ? `O campo código na linha ${line} é obrigatório` : null
const validationNameIsNull = (row, line, position) => !row[position] ? `O campo nome na linha ${line} é obrigatório` : null
const validationUnitaryValueIsNull = (row, line, position) => !row[position] ? `O campo valor unitário na linha ${line} é obrigatório` : null
const validationTableIsNull = (row, line, position) => !row[position] ? `O campo Tabela na linha ${line} é obrigatório` : null
const validationCDIsNull = (row, line, position) => !row[position] ? `O campo CD na linha ${line} é obrigatório` : null
const validationTableNumber = (row, line, position) => row[position] && !ITEM.TISS_TABLE_OPTIONS.map(option => option.value).includes(row[position].toString()) ? `O campo tabela na linha ${line} deve conter um dos valores [${ITEM.TISS_TABLE_OPTIONS.map(option => option.value).join(', ')}], valor inserido: ${row[position]}` : null
const validationCDType = (row, line, position) => row[position] && !ITEM.EXPENSE_CD_TYPES.includes(row[position].toString()) ? `O campo CD na linha ${line} deve conter um dos valores [${ITEM.EXPENSE_CD_TYPES.join(', ')}], valor inserido: ${row[position]}` : null
const validationValue = (row, line, position) => row[position] && isNaN(parseFloat(row[position].toString().replace(',', '.'))) % 1 !== 0 ? `O campo Valor único na linha ${line} deve ser um número` : null